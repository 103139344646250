import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from '@/state/store';

import BootstrapVueNext from 'bootstrap-vue-next';
import VueApexCharts from "vue3-apexcharts";
import Vuelidate from '@vuelidate/core';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import * as VueGoogleMaps from 'vue3-google-map';
import vClickOutside from "click-outside-vue3";

import { initFirebaseBackend } from './helpers/firebase/authUtils';
import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

// Import global SCSS styles
import '@/assets/scss/app.scss';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import 'bootstrap/dist/js/bootstrap.bundle'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVueNext)
app.use(Vuelidate)
app.use(vClickOutside)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
});
// Mount the app
app.config.productionTip = false;
app.mount('#app');
